

.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  /* background: transparent; */
  height: 64px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 2rem;
  position: fixed;
  top: 0;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  width: 100%;
  /* max-width: 1500px; */
}

.navbar-logo {
  display: block;
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  margin-top: 93px;
  cursor: pointer;
  text-decoration: none;
  font-size: 9rem;
  height: 126px;
  aspect-ratio: 412/126;
  background: url('../assets/images/logo.svg') no-repeat;
  background-size: contain;
  background-position: top left;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
  height: 64px;
}

.nav-item {
  height: 64px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.2rem 0.6rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: center;
    height: calc(100vh - 64px);
    top: 64px;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    margin: 0;
    margin-right: auto;
    margin-left: 13px;
    height: 66%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}