.hero-container {
  background: url('../assets/images/Melech-Button_lowres.jpg') center center/cover no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 32;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    color: #fff;
    font-size: 50px;
    margin-top: -100px;
  }
}

.hero-container > p {
  font-size: 30px;
}

.btn-mobile {
  display: block;
  text-decoration: none;
}

.btn {
  width: 100%
}