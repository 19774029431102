* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Codystar';
}

p {
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 16px;
}

strong{
  font-size: 26px;
}

a.custom-link {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: black;
  text-decoration: none;
  display: inline;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 960px) {
  a.custom-link {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 700;
    color: black;
    text-decoration: none;
    display: inline;
    position: relative;
    overflow: hidden;
  }
}

.custom-link:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  bottom: -5px;
  background: rgb(97, 97, 97);
  height: 2px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.custom-link:hover:after,
.custom-link:focus:after,
.custom-link:active:after {
  left: 0;
  right: auto;
  width: 100%;
}

a.bio-link {
  color: rgb(255, 255, 255);
  text-decoration: underline;
  font-family: 'Open Sans', sans-serif;
}

.home,
.about,
.hero-container,
.music,
.contact,
.mailing-list {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}


.about {
  background-image: url('./assets/images/Melech-tree_lowres.jpg');
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.music {
  background-image: url('./assets/images/Melech2019_lowres.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 10px;
}

.contact {
  background-image: url('./assets/images/melech-smile_lowres.jpg');
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
}

/* .mailing-list {
  background-image: url('./assets/images/melech-smile_lowres.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  display: flex;
  justify-content: flex-end;
} */

.player-container {
  width: 700px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@media screen and (max-width: 1400px) {
  .Player-tracklist-container {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .player-container {
    width: auto;
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
    margin-top: 20%;
  }
}

@media (min-width: 300px) and (max-width: 440px) {
  .player-container {
    margin-top: 10rem;
    width: auto;
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
  }
}

@media (min-width: 440px) and (max-width: 513px) {
  .player-container {
    width: auto;
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
    margin-top: 100%;
  }
}

@media (min-width: 513px) and (max-width: 700px) {
  .player-container {
    width: auto;
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
    margin-top: 60%;
  }
}

@media (min-width: 700px) and (max-width: 900px) {
  .player-container {
    width: auto;
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
    margin-top: 30%;
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .player-container {
    width: auto;
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
    margin-top: 30%;
  }
}





iframe {
  margin: 15px;
}

.mix-container {
  width: auto;
  align-items: stretch;
  display: flex;
}



